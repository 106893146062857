<template>
  <div>
    <b-col class="p-0">

      <b-card no-body>
        <b-card-header>
          <div>
            <b-card-title class="mb-1">
              {{ $t('Filters') }}
            </b-card-title>
          </div>
        <!-- datepicker -->
        </b-card-header>
        <b-card-body
          class="pt-0 pb-2 pl-2 pr-2"
          style="margin-bottom: 126px;"
        >
          <b-row style="margin-top: 0.8rem;">
            <!-- Date -->
            <b-col
              cols="12"
              class="mb-md-0 mt-0"
            >
              <b-form-group
                label-for="coupon-start-date"
                :label="$t('Date')"
              >
                <flat-pickr
                  v-model="dateLocal"
                  :config="{
                    mode: 'range',
                    allowInput: true,
                    dateFormat: 'Y-m-d',
                    maxDate: 'today',
                    altInput: true,
                    altFormat: 'd F Y',
                  }"
                  class="form-control"
                  :placeholder="$t('Date')"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </div>
</template>

<script>
import {
  BCard, BCardHeader,
  BCardTitle,
  BCardBody, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormGroup,
    flatPickr,
  },
  model: {
    prop: 'dateFilter',
    event: 'date-filter-update',
  },
  props: {
    dateFilter: {
      type: String,
      default: '',
    },
  },
  computed: {
    dateLocal: {
      get() {
        return this.dateFilter
      },
      set(value) {
        this.$emit('update:dateFilter', value)
      },
    },
  },
}
</script>

<style lang="scss">
.card {
  .card-title {
    font-size: 1.07rem;
  }
  .card-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
</style>
