<template>
  <section id="home">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="4"
      >
        <filters
          :date-filter.sync="dateFilter"
        />
      </b-col>
      <b-col
        xl="4"
        md="4"
      >
        <statistics :data="homeData.statistics" />
      </b-col>
      <b-col
        xl="4"
        md="4"
      >
        <usage-chart :data="homeData.usage" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <daily-report
          :data="homeData.daily"
          :broadcast-year.sync="broadcastYear"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'

import Filters from './Filters.vue'
import Statistics from './Statistics.vue'
import UsageChart from './UsageChart.vue'
import DailyReport from './DailyReport.vue'
import homeStoreModule from './homeStoreModule'

export default {
  components: {
    BRow,
    BCol,

    Filters,
    Statistics,
    UsageChart,
    DailyReport,
  },
  data() {
    return {
      intervalHome: null,
      intervalTime: 30000,
      dateFilter: '',
      broadcastYear: '',
      data: {
        statistics: [
          {
            icon: 'CreditCardIcon',
            color: 'light-success',
            title: '230k',
            subtitle: this.$i18n.t('Line'),
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'UserIcon',
            color: 'light-info',
            title: '8.549k',
            subtitle: this.$i18n.t('Customer'),
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'CheckIcon',
            color: 'light-danger',
            title: '1.423k',
            subtitle: this.$i18n.t('Follow'),
            customClass: 'mb-2 mb-sm-0',
          },
          {
            icon: 'XIcon',
            color: 'light-warning',
            title: '2',
            subtitle: this.$i18n.t('Unfollow'),
            customClass: '',
          },
        ],
        usage: {
          series: [80, 16, 4],
        },
        daily: {
          years: ['2021', '2020', '2019'],
          price: '25,852',
          budget: '56,800',
          revenueReport: {
            series: [
              {
                name: 'Follow',
                data: [2, 3, 6, 12, 2, 4, 22, 5, 14, 2, 3, 6, 12, 2, 4, 22, 5, 14, 4, 9],
              },
              {
                name: 'Unfollow',
                data: [-1, -1, 0, -2, 0, 0, -3, -1, -2, -1, -1, 0, -2, 0, 0, -3, -1, -2, 0, -3],
              },
            ],
          },
          budgetChart: {
            series: [
              {
                data: [200, 300, 600, 1200, 200, 400, 2200, 500, 1400],
              },
              {
                data: [100, 100, 0, 200, 0, 0, 300, 100, 200],
              },
            ],
          },
        },
      },
      homeData: {},
    }
  },
  computed: {
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  watch: {
    dateFilter: {
      handler() {
        this.getHome()
      },
      deep: true,
    },
    broadcastYear: {
      handler() {
        this.getHome()
      },
      deep: true,
    },
  },
  mounted() {
    this.getHome()
  },
  beforeDestroy() {
    clearInterval(this.intervalHome)
  },
  methods: {
    getHome() {
      const { resource } = this.$router.currentRoute.meta
      // console.log(resource)
      if (resource !== 'Auth' && resource !== undefined && resource !== null) {
        // console.log('get...')
        store
          .dispatch('home-store/getHome', {
            language: this.$i18n.locale,
            date: this.dateFilter,
            broadcast_year: this.broadcastYear,
            lineOaId: this.lineOaId,
          })
          .then(response => {
            this.homeData = response.data
            clearInterval(this.intervalHome)
            this.intervalHome = setInterval(() => {
              this.getHome()
            }, this.intervalTime)
          })
          .catch(() => {
          })
      }
    },
  },
  setup() {
    // Register module
    if (!store.hasModule('home-store')) store.registerModule('home-store', homeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('home-store')) store.unregisterModule('home-store')
    })

    return {}
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
