var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-col',{staticClass:"p-0"},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('div',[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('Filters'))+" ")])],1)]),_c('b-card-body',{staticClass:"pt-0 pb-2 pl-2 pr-2",staticStyle:{"margin-bottom":"126px"}},[_c('b-row',{staticStyle:{"margin-top":"0.8rem"}},[_c('b-col',{staticClass:"mb-md-0 mt-0",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"coupon-start-date","label":_vm.$t('Date')}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                  mode: 'range',
                  allowInput: true,
                  dateFormat: 'Y-m-d',
                  maxDate: 'today',
                  altInput: true,
                  altFormat: 'd F Y',
                },"placeholder":_vm.$t('Date')},model:{value:(_vm.dateLocal),callback:function ($$v) {_vm.dateLocal=$$v},expression:"dateLocal"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }